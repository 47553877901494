<ejs-dialog id="logoutDialog" #logoutDialog [showCloseIcon]='true' width="467px" height="203" *ngIf="showModal"
    [isModal]="true" [position]="{ X: 'center', Y: 'center' }" (close)="dialogClose()">
    <ng-template #content>
        <div class="text-center">
                <div class="font-size-21">
                    <span class="poppins-semibold">Are you sure you want to Logout?</span>
                </div>
        </div>
    </ng-template>
    <ng-template #footerTemplate>
        <div fxLayout="row" class="buttonDiv" fxLayoutAlign="center center">
            <button ejs-button fxFlex="48" (click)="dialogClose()" class="e-control e-btn e-outline mr-10" type="button"
                data-ripple="true">
                <span class="mr-5">
                    NO
                </span>
            </button>
            <button ejs-button fxFlex="48" (click)="openLogout()" class="e-control e-btn e-primary" type="button"
            data-ripple="true">
                 <span class="mr-5">
                   YES
                 </span>
        </button>
        </div>
    </ng-template>
</ejs-dialog>
