<div class="toppanel" fxLayout="row" fxFlex="100" fxLayoutAlign="start start">
  <div fxLayout="column" fxFlex="20" fxLayoutAlign="start start">
    <img src="assets/custom/headerLogo.png" class="pointer" routerLink="/dashboard" alt="GetMeFitLogo"
      width="50px" />
  </div>
  <div fxLayout="column" fxFlex="80" fxLayoutAlign="end end">
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="end end">
      <div class="menu-header-icon" routerLink="/dashboard">
        <img src="assets/custom/home-icon.svg" alt="HomeIcon" width="17px" />
      </div>
    <div class="menu-header-icon"  (click)="openAllNotifications()">
        <img src="assets/custom/notification-icon.svg"  alt="NotificationsIcon" width="17px" />
        <span class="e-badge e-badge-danger e-badge-overlap e-badge-notification" *ngIf="isNewNotification"><span
            class="notification-count">{{notificationCount >= 10 ? notificationCount : '0'+notificationCount}}</span></span>
      </div>
      <div class="menu-header-icon" (click)="openSettingMenuDialog()"  (clickOutside)="outSideClick($event)" appClickOutside>
        <img src="assets/custom/settings-icon.svg" alt="SettingIcon" width="18px" />
      </div>
    </div>
  </div>
</div>

<app-setting-menu-dialog data="Hello" popUpTitle="Dailog Box"></app-setting-menu-dialog>
<div id="toast" #toast></div>
<app-notification-popup></app-notification-popup>

