import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { AlertModule } from 'src/app/shared/alert/alert.module';
import { RouterModule, Routes } from '@angular/router';
import { NotificationPopupComponent } from './notification-popup.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { SpinnerModule } from "../../../shared/spinner/spinner.module";
import { EmptyDataModule } from "../../../shared/empty-data/empty-data.module";

const components = [
    NotificationPopupComponent
];

const imports = [
  FlexLayoutModule,
  FormsModule,
  ReactiveFormsModule,
  ButtonModule,
  AlertModule,
  DialogModule
];

const routes: Routes = [
  {
    path: '',
    component: NotificationPopupComponent,
  }
];

@NgModule({
    declarations: [components],
    exports: [
        RouterModule,
        components
    ],
    imports: [
        imports,
        CommonModule,
        RouterModule.forChild(routes),
        SpinnerModule,
        EmptyDataModule
    ]
})

export class NotificationPopupModule { }