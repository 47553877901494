import { notifications } from 'src/app/pages/notifications/service/notifications.service.data';
import { Injectable } from "@angular/core";
import { from, Observable, Subject } from "rxjs";
import { Store } from "src/app/core/store/store";
import { environment } from "src/environments/environment";
import { MyNotificationLoadedState, MyNotificationLoadingErrorState, MyNotificationLoadingState } from "./notifications.state";
import { MarkAllReadLoadedState , MarkAllReadLoadingErrorState, MarkAllReadLoadingState } from './notifications.state';
import { map } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class MyNotificationsService extends Store.AbstractService {


  // My all the notifications data
  getMyNotificationList(payload): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new MyNotificationLoadingState());
    }, 0);

    const path = environment.api.notifications.getMyNotificationList.endpoint;

    me.controller
      .post(path, payload, environment.api.core.defaultBase, {
        Authorization: true,
      })
      .subscribe(
        (data: any) => {
          output.next(new MyNotificationLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new MyNotificationLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }


  // Mark all as read 
  MarkAllAsRead(myNotificationIdArray): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new MarkAllReadLoadingState());
    }, 0);

    const path = environment.api.notifications.markAllRead.endpoint;

    me.controller
      .post(path, myNotificationIdArray, environment.api.core.defaultBase, {
        Authorization: true,
      })
      .subscribe(
        (data: any) => {
          output.next(new MarkAllReadLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new MarkAllReadLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }


  // Get My notification count for header (red dot)

  getMyNotificationCount(payload){
    const me = this;
    const path = environment.api.notifications.getMyNotificationList.endpoint;
    return me.controller
      .post(path, payload, environment.api.core.defaultBase, {
        Authorization: true,
      })
      //pipe(map((json: Array<any>) => me.modifyJson(json)));
  }
  // public modifyJson(jsonData): any[] {
  //   const obj = [];
  //   jsonData.data.forEach((v) => {
  //     obj.push({ label: v?.title, value: v?.id });
  //   });
  //   return obj;
  // }


}
