import { Location } from "@angular/common";
import { Injectable, NgZone } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import * as moment from "moment";
import { NgxPermissionsObject, NgxPermissionsService } from "ngx-permissions";
import { Observable, Subject } from "rxjs";
import { CustomUtilityService } from "src/app/shared/common/services/custom-utility.service";
import { SnakbarService } from "src/app/shared/common/snakbar/snakbar.service";
import { Subscriptions } from "src/app/utility/common.model";
import { IDGenerator } from "src/app/utility/id-generator.util";
import { AppLocalStorage } from "src/app/utility/local-storage.util";
import { environment } from "src/environments/environment";
import { AppError } from "../error/error.model";
import { Store } from "../store/store";
import {
  AuthTokenResponse,
  LoginCredentials,
  LogoutResponse,
  Session,
  SessionAccount,
  SessionAccountResponse,
  signUpCredentials,
} from "./session.model";
import {
  SessionCreatingErrorState,
  SessionCreatingState,
  SessionDestroyedState,
  SessionDestroyingState,
  SessionDestroyingErrorState,
  LogoutLoadingState,
  LogoutLoadedState,
  LogoutErrorState,
  GoogleSignInLoadingState,
  GoogleSignInLoadedState,
  GoogleSignInErrorState,
  FacebookSignInLoadingState,
  FacebookSignInLoadedState,
  FacebookSignInErrorState,
} from "./session.state";

//import { MyNotificationPayload } from 'src/app/pages/notifications/service/my-notification.model';
declare const gapi: any;

@Injectable({
  providedIn: "root",
})
export class SessionService extends Store.AbstractService {
  private static RESTRICTED_REDIRECT_URLS: string[] = [
    "/loading",
    "/login",
    "/signup",
    "/forgot-password",
  ];

  // sessionValidityTimer: NodeJS.Timer;
  sessionValidityTimer: any;

  private redirectURL: string;
  private redirectToken: string;
  session: Session;
  account: SessionAccount;
  tabSessionId: string;
  user: any;
  private auth2: any;

  private subscriptions: Subscriptions = {
    sessionValidate: null,
  };
  public temp: boolean = false;

  constructor(
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private location: Location,
    private snakbarService: SnakbarService,
    private ngZone: NgZone
  ) {
    super();
    const me = this;

    me.tabSessionId = IDGenerator.newId();

    me.router.events.subscribe((re: RouterEvent) => {
      me.onRouterEvent(re);
    });

    window["getAccessToken"] = () => {
      return me.session.token;
    };
    this.start();
  }

  init() {
    const me = this;
    me.setRedirectURL();
    return new Promise<void>((resolve, reject) => {
      me.initSession(resolve, reject);
    });
  }

  redirectToLoginPage() {
    const me = this;
    me.setRedirectURL();
    me.router.navigate(["/login"]);
  }

  redirectToHomePage() {
    const me = this;
     let redirectTo = "/dashboard/upcoming-sessions";
    // this.ngZone.run(() => {
    //   // Your navigation code here, e.g., using the Angular Router
    //   this.router.navigate(['//dashboard/upcoming-sessions']); // Replace with your route
    // });
     me.router.navigate([redirectTo]);
  }

  redirectToProfileSetup() {
    const me = this;
    let redirectTo = "/profile-setup";
    me.router.navigate([redirectTo]);
  }

  private setRedirectURL(url?: string) {
    const me = this;
    const sessionInfo = AppLocalStorage.get("SESSION", "SESSION");
    let redirectURL: string = url;
    if (sessionInfo?.token) {
      redirectURL = url || me.location.path();
    }

    me.redirectToken = IDGenerator.newId();

    if (SessionService.RESTRICTED_REDIRECT_URLS.indexOf(redirectURL) !== -1) {
      redirectURL = null;
    }

    me.redirectURL = redirectURL;
    // console.log(me.redirectURL);
  }

  private isSSORequest(): boolean {
    return window.location.hash.startsWith("#id_token");
  }

  private isSignUpPage(): boolean {
    return SessionService.RESTRICTED_REDIRECT_URLS.indexOf("/signup") !== -1;
  }

  private isForgotPasswordPage(): boolean {
    return (
      SessionService.RESTRICTED_REDIRECT_URLS.indexOf("/forgot-password") !== -1
    );
  }

  public login(credentials: LoginCredentials): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new SessionCreatingState());
    }, 0);

    me.controller
      .post<AuthTokenResponse>(
        environment.api.session.login.endpoint,
        credentials
      )
      .subscribe(
        (data: AuthTokenResponse) => {
          // console.log("data ", data);
          if (data) {
            me.session = {
              token: data.data.jwt,
              id: IDGenerator.newId(),
              expiry: moment().add(1, "day").toDate(),
            };

            AppLocalStorage.set("SESSION", "SESSION", me.session);

            me.loadAccount().subscribe(() => {
              // if setup done then redirect to dashboard otherwise ask to setup profile
              this.user = AppLocalStorage.get("USER_DETAILS", "USER_DETAILS");

              if (
                (this.user?.isProfileSetupDone == true &&
                  this.user?.isProfileSetupSkipped == true) ||
                (this.user?.isProfileSetupDone == false &&
                  this.user?.isProfileSetupSkipped == false) ||
                this.user?.isProfileSetupSkipped == true ||
                this.user?.isProfileSetupDone == true
              ) {
                me.redirectToHomePage();
              } else {
                this.redirectToProfileSetup();
              }
              // me.redirectToHomePage();

              if (
                data.status == "LOGIN_SUCCESSFUL" ||
                data.status == "S_L_10001"
              ) {
                me.snakbarService.showToast(document.getElementById("toast"), {
                  title: "Success",
                  content: "You are halfway there already! ",
                  position: {
                    X: "Right",
                  },
                  type: "SUCCESS",
                  showCloseButton: true,
                });
              }
              me.temp = true;
              me.startSessionValidation();
              output.complete();
            });
          } else {
            setTimeout(() => {
              output.error(
                new SessionCreatingErrorState(new AppError("No Token Received"))
              );
              output.complete();
            }, 0);
          }
        },
        (e: Error) => {
          setTimeout(() => {
            output.error(new SessionCreatingErrorState(AppError.fromError(e)));
            output.complete();
          }, 0);
        }
      );

    return output;
  }

  public logout(): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new LogoutLoadingState());
    }, 0);

    me.controller
      .get(environment.api.session.logout.endpoint, null, undefined, {
        Authorization: true,
      })
      .subscribe(
        (data: LogoutResponse) => {
          if (data) {
            me.clear();
            output.next(new LogoutLoadedState(data));
            window.localStorage.clear();
            // console.log("window.sessionStorage.clear ");
            me.router.navigate(["/login"]);
            output.complete();
          }
        },
        (e: Error) => {
          setTimeout(() => {
            output.error(new LogoutErrorState(AppError.fromError(e)));
            output.complete();
          }, 0);
        }
      );
    return output;
  }
  private start(): void {
    window.addEventListener("storage", this.storageEventListener.bind(this));
  }
  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      if (event?.key && event.key == "logout-event") {
        // console.log("storageEventListener ~ event", event.newValue);
        this.logout();
      }
    }
  }
  private stop(): void {
    window.removeEventListener("storage", this.storageEventListener.bind(this));
  }

  ngOnDestroy() {
    this.stop();
  }


  // authenticateWithGoogle() {
  //   const clientId = "385033597927-vd1h3q8llhv40tm12ct147ea69od33ph.apps.googleusercontent.com";

  //   gapi.load('auth2', () => {
  //     gapi.auth2
  //       .getAuthInstance({ client_id: clientId, scope: 'profile email' })
  //       .then((auth2) => {
  //         auth2.signIn().then((googleUser) => {
  //           // Getting profile object
  //           const profile = googleUser.getBasicProfile();
  //           // Setting data to local storage (you can also send it to a server)
  //           localStorage.setItem('token', googleUser.getAuthResponse().id_token);
  //           localStorage.setItem('image', profile.getImageUrl());
  //           localStorage.setItem('name', profile.getName());
  //           localStorage.setItem('email', profile.getEmail());
  //           // You can emit an event or perform any other actions here
  //           // For example, you can create an event emitter and emit an event to notify the component.
  //           // this.signInSuccess.emit(googleUser);
  //         });
  //       })
  //       .catch((error) => {
  //         console.error('Google sign-in error:', error);
  //       });
        
  //   });
  // }


  public googleLogin(requestBody: any): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new GoogleSignInLoadingState());
    }, 0);

    me.controller
      .post(
        environment.api.session.googleSignIn.endpoint,
        requestBody,
        undefined,
        { Authorization: true }
      )
      .subscribe(
        (data: any) => {
          if (data) {
            me.session = {
              token: data.data.jwt,
              id: IDGenerator.newId(),
              expiry: moment().add(1, "day").toDate(),
            };

            AppLocalStorage.set("SESSION", "SESSION", me.session);
            AppLocalStorage.set("PROVIDER", "PROVIDER", "Google");
            me.loadAccount().subscribe(() => {
              this.user = AppLocalStorage.get("USER_DETAILS", "USER_DETAILS");
              if (
                (this.user?.isProfileSetupDone == true &&
                  this.user?.isProfileSetupSkipped == true) ||
                (this.user?.isProfileSetupDone == false &&
                  this.user?.isProfileSetupSkipped == false) ||
                this.user?.isProfileSetupSkipped == true ||
                this.user?.isProfileSetupDone == true
              ) {
                me.redirectToHomePage();
              } else {
                this.redirectToProfileSetup();
              }

              if (data.status == "USER_SIGNED_IN_SUCCESSFULLY") {
                me.snakbarService.showToast(document.getElementById("toast"), {
                  title: "Success",
                  content: "You are halfway there already! ",
                  position: {
                    X: "Right",
                  },
                  type: "SUCCESS",
                  showCloseButton: true,
                });
              }

              me.temp = true;
              me.startSessionValidation();
              output.complete();
            });
          }
        },
        (e: Error) => {
          setTimeout(() => {
            output.error(new GoogleSignInErrorState(AppError.fromError(e)));
            output.complete();
          }, 0);
        }
      );
    return output;
  }

  public facebookLogin(requestBody: any): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new FacebookSignInLoadingState());
    }, 0);

    me.controller
      .post(
        environment.api.session.facebookSignIn.endpoint,
        requestBody,
        undefined,
        { Authorization: true }
      )
      .subscribe(
        (data: any) => {
          if (data) {
            me.session = {
              token: data.data.jwt,
              id: IDGenerator.newId(),
              expiry: moment().add(1, "day").toDate(),
            };

            AppLocalStorage.set("SESSION", "SESSION", me.session);
            AppLocalStorage.set("PROVIDER", "PROVIDER", "Facebook");

            me.loadAccount().subscribe(() => {
              this.user = AppLocalStorage.get("USER_DETAILS", "USER_DETAILS");

              if (
                (this.user?.isProfileSetupDone == true &&
                  this.user?.isProfileSetupSkipped == true) ||
                (this.user?.isProfileSetupDone == false &&
                  this.user?.isProfileSetupSkipped == false) ||
                this.user?.isProfileSetupSkipped == true ||
                this.user?.isProfileSetupDone == true
              ) {
                me.redirectToHomePage();
              } else {
                this.redirectToProfileSetup();
              }
              if (data.status == "USER_SIGNED_IN_SUCCESSFULLY") {
                me.snakbarService.showToast(document.getElementById("toast"), {
                  title: "Success",
                  content: "You are halfway there already! ",
                  position: {
                    X: "Right",
                  },
                  type: "SUCCESS",
                  showCloseButton: true,
                });
              }

              me.temp = true;
              me.startSessionValidation();
              output.complete();
            });
          }
        },
        (e: Error) => {
          setTimeout(() => {
            output.error(new FacebookSignInErrorState(AppError.fromError(e)));
            output.complete();
          }, 0);
        }
      );
    return output;
  }

  public signupLogin(appToken: string) {
    const me = this;
    if (appToken) {
      me.session = {
        token: appToken,
        id: IDGenerator.newId(),
        expiry: moment().add(1, "day").toDate(),
      };

      AppLocalStorage.set("SESSION", "SESSION", me.session);

      me.loadAccount().subscribe(() => {
        me.redirectToHomePage();
        me.startSessionValidation();
      });
    }
  }

  private loadAccount(): Observable<void> {
    const me = this;
    const output = new Subject<void>();

    const path = environment.api.auth.me.endpoint;

    me.controller.get(path, null, undefined, { Authorization: true }).subscribe(
      (data: SessionAccountResponse) => {
        me.account = data?.data;
        const storage = localStorage.setItem(
          "userID",
          me.account?.userId.toString()
        );
        // const notificationCount = localStorage.setItem(
        //   "notificationCount",
        //   me.account.notificationCount
        // );

        let userDetails = {
          firstname: me.account?.firstName,
          lastname: me.account?.lastName,
          fullname: me.account?.fullName,
          email: me.account?.email,
          username: me.account?.username,
          phoneNumber: me.account?.phoneNumber,
          dateOfBirth: me.account?.dateOfBirth,
          status: me.account?.status,
          profilePictureURL: me.account?.profilePictureURL,
          serviceProviderTierId: me.account?.serviceProviderTierId,
          serviceProviderTierTitle: me.account?.serviceProviderTierTitle,
          bio: me.account?.bio,
          verificationRequestStatus: me.account?.verificationRequestStatus,
          identityVerificationStatus: me.account?.identityVerificationStatus,
          experience: me.account?.experience,
          categoryData: me.account?.categoryData,
          forceLogoutTime: me.account?.forceLogoutTime,
          role: me.account?.role,
          isProfileSetupDone: me.account?.isProfileSetupDone,
          isProfileSetupSkipped: me.account?.isProfileSetupSkipped,
          userId: me.account?.userId,
        };
        AppLocalStorage.set("USER_DETAILS", "USER_DETAILS", userDetails);

        // if (me.account?.firms) {
        //   me.logoPath.next(me.account?.firms[0]?.logoPath);
        //   me.fullName.next(me.account?.fullName);
        //   const bannerURL =
        //     me.account?.firms[0]?.bannerPath &&
        //       me.account?.firms[0]?.bannerPath != ""
        //       ? JSON.parse(me.account?.firms[0]?.bannerPath)?.big
        //       : null;
        //   me.bannerPath.next(bannerURL);
        //   //Notification call
        //   me.myNotification();
        // }
        me.refreshPermissions(output);
      },
      (e: any) => {
        output.error(new Error("Failed to Load Account"));
        output.complete();
      }
    );

    return output;
  }

  public refreshAccountInfo() {
    const me = this;

    // Uncomment This
    me.loadAccount();
  }

  public async refreshAccount(): Promise<boolean> {
    const me = this;

    return new Promise<boolean>((resolve) => {
      me.loadAccount().subscribe(
        () => {
          resolve(true);
        },
        (error: Error) => {
          console.error(error);
          resolve(false);
        }
      );
    });
  }

  private refreshPermissions(subject: Subject<void>) {
    const me = this;

    // if (me.account && me.account.permissions) {
    //   const permissions = Object.keys(me.account.permissions);
    //   console.debug("Current User's Permissions", permissions);
    //   me.permissionsService.loadPermissions(permissions);
    //   subject.next();
    //   subject.complete();
    // } else {
    //   subject.error("INSUFFICIENT_PERMISSIONS");
    //   subject.complete();
    // }

    subject.next();
    subject.complete();
  }

  // RolePermission[]
  public getRolePermissionsFor(code: string): any[] {
    const me = this;
    if (me.account && me.account.permissions && me.account.permissions[code]) {
      return Object.values(me.account.permissions[code]);
    }
    return null;
  }

  private startSessionValidation() {
    const me = this;
    me.stopSessionValidation();
    me.sessionValidityTimer = setTimeout(() => {
      me.validateSession();
    }, 600000);
  }

  private stopSessionValidation() {
    const me = this;
    if (me.sessionValidityTimer) {
      clearTimeout(me.sessionValidityTimer);
    }
  }

  private validateSession() {
    const me = this;

    //TODO: Uncomment this whenever required

    // console.debug("Validating Session");

    // const path = environment.api.auth.validate.endpoint;
    // me.controller.get(path, null, undefined, { Authorization: true }).subscribe(
    //   (data: any) => {
    //     me.startSessionValidation();
    //   },
    //   (e: any) => {
    //     me.router.navigate(["logout"]);
    //   }
    // );
  }

  private myNotification() {
    const me = this;
    const accountId: string[] = [];
    accountId.push(me.account?.userId.toString());
  }

  private initSession(resolve: () => void, reject: (reason: AppError) => void) {
    const me = this;

    const session: Session = AppLocalStorage.get(
      "SESSION",
      "SESSION"
    ) as Session;

    if (me.isSessionActive(session)) {
      me.session = session;

      me.loadAccount().subscribe(
        () => {
          me.validateSession();
          resolve();
          //me.redirectToHomePage();
          me.temp = true;
        },
        (error) => {
          reject(new AppError(error));
        }
      );
      return;
    }

    if (me.isSSORequest()) {
      resolve();
      return;
    }

    // TODO: Need to verify this

    if (me.isSignUpPage()) {
      resolve();
      return;
    }

    if (me.isForgotPasswordPage()) {
      resolve();
      return;
    }

    // Redirect to Login
    me.router.navigate(["login"]);
    resolve();
  }

  public isActive(): boolean {
    return this.isSessionActive(this.session);
  }

  public clear(): Observable<any> {
    const output = new Subject<any>();
    const me = this;

    // Clear session local storage
    AppLocalStorage.clear("SESSION", "SESSION");
    AppLocalStorage.clear("USER_DETAILS", "USER_DETAILS");
    localStorage.clear();
    // sessionStorage.clear();

    // Stop session validation check
    me.stopSessionValidation();

    //stop notifications.
    // me.notificationService.stopTimer()

    // Reset Session
    me.session = null;

    setTimeout(() => {
      output.next();
    });

    return output;
  }

  public hasPermissionsAll(permissions: string[]): boolean {
    const me = this;
    const sessionPermissions: NgxPermissionsObject =
      me.permissionsService.getPermissions();
    let flag = true;

    for (const permission of permissions) {
      if (!sessionPermissions[permission]) {
        flag = false;
        break;
      }
    }

    return flag;
  }

  private isSessionActive(session: Session): boolean {
    return !!session;
  }

  private onRouterEvent(event: RouterEvent) {
    const me = this;

    if (event instanceof NavigationEnd) {
      switch (event.url) {
        case "/loading":
          me.redirectToken = null;
          break;
      }
    }
  }
}
