<div class="control-section">
    <ejs-dialog #settingDialog [closeOnEscape]="true" [visible]="showmodal" [animationSettings]='animationSettings'
        [target]='targetElement' [position]="position">
        <div class="profile-links ph-20">
            <div class="profile-link" fxLayout="row" (click)="openProfile()">
                <p class="dm-sans-medium font-size-15 mv-5">Profile </p>
            </div>
            <div class="profile-link" fxLayout="row">
                <p class="dm-sans-medium font-size-15 mv-5 disabled">Payments (Bank Details)</p>
            </div>

            <div class="profile-link" fxLayout="row">
                <p class="dm-sans-medium font-size-15 mv-5 disabled">Notification</p>
            </div>

            <div class="profile-link" fxLayout="row" (click)="openFAQ()">
                <p class="dm-sans-medium font-size-15 mv-5">FAQs</p>
            </div>

            <div class="profile-link" fxLayout="row" (click)="openSupport()">
                <p class="dm-sans-medium font-size-15 mv-5">Help and Support</p>
            </div>
            <div class="profile-link" fxLayout="row" (click)="openCredit()">
                <p class="dm-sans-medium font-size-15 mv-5">Earnings Overview</p>
            </div>

            <div class="profile-link" fxLayout="row">
                <p class="dm-sans-medium font-size-15 mv-5 disabled">Invite</p>
            </div>

            <div class="profile-link" fxLayout="row" (click)="clickLogout()">
                <p class="dm-sans-medium font-size-15 mv-5">Log out</p>
            </div>
        </div>
    </ejs-dialog>
</div>

<app-logout-dialog></app-logout-dialog>