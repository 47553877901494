import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { AppError } from "src/app/core/error/error.model";
import {
  LogoutErrorState,
  LogoutLoadedState,
  LogoutLoadingState,
} from "src/app/core/session/session.state";
import { SessionService } from "src/app/core/session/session.service";
import { Router } from "@angular/router";
import { SnakbarService } from "../../common/snakbar/snakbar.service";
import { Store } from "src/app/core/store/store";
import { SocialAuthService } from "@abacritt/angularx-social-login";


@Component({
  selector: "app-logout-dialog",
  templateUrl: "./logout-dialog.component.html",
  styleUrls: ["./logout-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LogoutDialogComponent implements OnInit {
  headerText: string;
  subHeaderText: string;
  showModal: boolean = false;
  openLogoutDialog: any;
  data: any;
  error: AppError;
  empty: boolean;
  logoutLoading: boolean = false;
  @ViewChild("logOutToast")
  public logoutToast: ElementRef;
  constructor(private sessionService: SessionService,
    private router: Router,
    private snakbarService: SnakbarService,
    private authService: SocialAuthService) { }

  ngOnInit(): void { }
  public openDialog() {
    const me = this;
    me.showModal = true;
  }

  removeDialogFromDOM() {
    setTimeout(() => {
      let logoutModal: any = document.getElementById("logoutDialog");
      logoutModal && logoutModal.remove();
    }, 10);
  }

  dialogClose() {
    const me = this;
    me.showModal = false;
    me.removeDialogFromDOM();
  }

  // For Logout API Call
  openLogout() {
    const me = this;
    // console.log("LOGOUT DONE");
    window.localStorage.setItem('logout-event', Math.random().toString())


    me.sessionService.logout().subscribe(
      (state: Store.State) => {
        if (state instanceof LogoutLoadingState) {
          me.onLoading(state);
          return;
        }

        if (state instanceof LogoutLoadedState) {
          me.onLoaded(state);
          return;
        }
      },
      (state: LogoutErrorState) => {
        me.onLoadingError(state);
      }
    );
  }

  private onLoading(state: LogoutLoadingState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = null;
    me.logoutLoading = true;
  }

  private onLoadingError(state: LogoutErrorState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.logoutLoading = true;
  }

  private onLoaded(state: LogoutLoadedState) {
    const me = this;
    me.data = state.data;
    // this.authService.authState.subscribe((user) => {
    //   if (user != null) {
    //     me.authService.signOut(true);
    //   }
    // });
    this.router.navigate(["/login"]);
    me.logoutLoading = false;
    me.snakbarService.showToast(document.getElementById("logoutToast"), {
      title: "Success",
      content: "Logged out. Hope to see you again soon!",
      type: "SUCCESS",
      showCloseButton: true,
    });
  }

}
