import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentPreviewComponent } from './document-preview.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { SpinnerModule } from '../../spinner/spinner.module';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { DataHandlerModule } from '../../data-handler/data-handler.module';

@NgModule({
  declarations: [DocumentPreviewComponent],
  imports: [CommonModule, DialogModule, SpinnerModule, ButtonModule, NgxDocViewerModule, DataHandlerModule],
  exports: [DocumentPreviewComponent]
})
export class DocumentPreviewModule { }
