import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import { SessionService } from "src/app/core/session/session.service";
import { AppLocalStorage } from "src/app/utility/local-storage.util";
import { SnakbarService } from "src/app/shared/common/snakbar/snakbar.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService, private router: Router, private snakbarService: SnakbarService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const me = this;
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status == 500) {
          me.snakbarService.showToast(
            document.getElementById("appErrorToast"),
            {
              title: "Internal Server Error",
              position: {
                X: "Right",
              },
              type: "ERROR",
              showCloseButton: true,
            }
          );
        } else if (err.status == 403) {
          me.sessionService.clear().subscribe(() => { });
          if (!AppLocalStorage.get("SESSION", "SESSION")) {
            let apiTimeOut;
            if (apiTimeOut) {
              clearTimeout(apiTimeOut);
            }
            apiTimeOut = setTimeout(() => {
              me.router.navigate(["/login"]);
              window.location.href = '/login'
              me.snakbarService.showToast(
                document.getElementById("appErrorToast"),
                {
                  title: "Your session has expired please login again",
                  position: {
                    X: "Right",
                  },
                  type: "ERROR",
                  showCloseButton: true,
                }
              );
            }, 1000);
          }

        }
        const error = err;
        return throwError(error);
      })
    );
  }
}
