import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { DialogComponent } from "@syncfusion/ej2-angular-popups";
import { AppError } from "src/app/core/error/error.model";
import { SessionService } from "src/app/core/session/session.service";

import { Store } from "src/app/core/store/store";
import { Router } from "@angular/router";
import { SnakbarService } from "../../common/snakbar/snakbar.service";
import { LogoutDialogComponent } from "../logout-dialog/logout-dialog.component";

@Component({
  selector: "app-setting-menu-dialog",
  templateUrl: "./setting-menu-dialog.component.html",
  styleUrls: ["./setting-menu-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SettingMenuDialogComponent implements OnInit {
  showmodal = false;
  @ViewChild(LogoutDialogComponent)
  public openLogoutDialog: LogoutDialogComponent;

  public confirmWidth: string = "266px";
  public animationSettings: Object = {
    effect: "Fade",
    duration: 400,
    delay: 0,
  };
  public position = { X: "right", Y: "top" };

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private snakbarService: SnakbarService
  ) { }

  @ViewChild("settingDialog") settingDialog: DialogComponent;
  // Create element reference for dialog target element.
  @ViewChild("container", { read: ElementRef, static: true })
  container: ElementRef;
  // The Dialog shows within the target element.
  // public targetElement: HTMLElement;
  public targetElement: string = ".control-section";

  ngOnInit(): void {
    // this.initilaizeTarget();
  }

  ngAfterViewInit(): void {
    const me = this;
    document.onclick = (args: any): void => {
      if (args.target.nodeName == "DIV" || args.target.nodeName == "rect") {
        if (me.showmodal == true) {
          me.showmodal = false;
        }
      }
    };
  }

  // Initialize the Dialog component target element.
  // public initilaizeTarget: any = () => {
  //   if (this.container != null) {
  //     this.targetElement = this.container.nativeElement.parentElement;
  //   }
  // };

  openProfile() {
    const me = this;
    me.router.navigate(['/profile-settings'])
    me.closeDialog();
  }

  openFAQ() {
    window.open('https://getmefitt.com/faqs/', '_blank');

    // const me = this;
    // me.router.navigate(['/faqs'])
    // me.closeDialog();
  }

  openSupport() {
    const me = this;
    me.router.navigate(['/support'])
    me.closeDialog();
  }
  openCredit() {
    const me = this;
    me.router.navigate(['/credit'])
    me.closeDialog();
  }

  openDialog() {
    if (this.showmodal == false) {
      this.showmodal = true;
    } else {
      this.closeDialog();
    }
  }

  closeDialog() {
    this.showmodal = false;
  }


  clickLogout() {
    this.openLogoutDialog.openDialog();
    this.closeDialog();
  }




}
