import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TextBoxModule } from "@syncfusion/ej2-angular-inputs";
import { SpinnerModule } from "src/app/shared/spinner/spinner.module";
import { ButtonModule } from "@syncfusion/ej2-angular-buttons";
import { FileUploaderModule } from "src/app/shared/file-uploader/file-uploader.module";
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { LogoutDialogComponent } from "./logout-dialog.component";



const imports = [
  FlexLayoutModule,
  FormsModule,
  ReactiveFormsModule,
  TextBoxModule,
  SpinnerModule,
  ButtonModule,
  DialogModule
];

@NgModule({
  declarations: [LogoutDialogComponent],
  imports: [
    CommonModule,
    imports,
    FileUploaderModule,
  ],
  exports: [LogoutDialogComponent],
})
export class LogoutDialogModule {}
