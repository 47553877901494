import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { SettingMenuDialogComponent } from './setting-menu-dialog.component';
import { RouterModule } from '@angular/router';
import { LogoutDialogModule } from '../logout-dialog/logout-dialog.module';

@NgModule({
  declarations: [SettingMenuDialogComponent],
  imports: [
    CommonModule,
    DialogModule,
    RouterModule,
    LogoutDialogModule
  ],
  exports: [SettingMenuDialogComponent]
})
export class SettingMenuDialogModule { }
