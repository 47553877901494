import { Store } from "src/app/core/store/store";
import { isDevMode } from "@angular/core";
import { apis } from "./apis";

export const environment = {
  production: false,
  localstorage: {
    key: "get_me_fit",
  },
  googleSSO: {
    clientId:
      "385033597927-vd1h3q8llhv40tm12ct147ea69od33ph.apps.googleusercontent.com",
    clientSecret: "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",
  },
  facebookSSO: {
    clientId: "214922697832270",
    clientSecret: "a27e3966eedbbe6b74e658335fa745d1",
  },
  zoomConfiguration: {
    sdkKey: "q6QMmaO2QN6JBPf5iboPrg",
    role: 1, //// Role - 0 (Participant) and Role - 1 (Host)
    leaveUrl: "/dashboard/session-history", // LeaveURL - to navigate url after disconnecting the call
  },
  api: {
    core: {
      base: "/api",
      mockBase: "https://demo9593669.mockable.io",
    } as Store.APIConfig,
    ...apis,
  },

  formats: {
    date: {
      // Moment JS date formats
      default: "MM-DD-YYYY",
      long: "D-MMM-YYYY",
      short: "D-MMM-YYYY",
    },
    dateTime: {
      // Moment JS date formats
      default: "MM-DD-YYYY HH:mm:ss",
    },
  },
};
