import { Pipe, PipeTransform } from "@angular/core";
import * as _moment from "moment";
@Pipe({
  name: "datePipe",
})
export class DatePipe implements PipeTransform {
  transform(input: string, formatType: string): any {
    const me = this;
    const format = me.dateFormat(formatType);

    if (!input) {
      return null;
    }

    return _moment.utc(input).local().format(format);
  }

  dateFormat(formatType: string): string {
    switch (formatType) {
      case "shortDate":
        return "DD MMM YYYY";
      case "normalDate":
        return "DD MMM YY";
      case "normalDateWithTime":
        return "d/M/yy h:mm a";
      case "normalTimeWithDate":
        return "h:mm A, DD/MM/yyyy";
      case "normalTimeWithDateNoComma":
        return "h:mm A DD/MM/yyyy";
      case "fullTimeAndDate":
        return "DD MMM YY HH:mm";
      case "mediumDate":
        return "DD MMM YYYY  hh:mm A";
      case "longDate":
        return "DD MMM YYYY hh:mm:ss A";
      case "normalDateWithDay":
        return "dddd, d/M/yy";
      case "yearMonthDate":
        return "YY-MM-DD";
      case "longTime":
        return "h:mm:ss a";
      case "mediumTime":
        return "h:mm A";
      case "FullYearDateAndTime":
        return "YYYY-MM-DD HH:mm:ss";
      case "FullYearDate":
        return "YYYY-MM-DD";
      case "shortDayAndMonth":
        return "ddd, MMM DD";
      case "GMFShortDate":
        return "MMM DD, YYYY";
      case "GMFLongDate":
        return "d MMMM yyyy, hh:mm a";
    }
  }
}

export const DateFormat = {
  parse: {
    dateInput: "input",
  },
  display: {
    dateInput: "DD MMM YY",
    monthYearLabel: "MMM YYYY",
  },
};

export const GlobalMatDatePickerFormatProvider = [];

@Pipe({
  name: "dateAgo",
  pure: true,
})
export class DateAgoPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    //console.log(value);

    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);

      // const hour = Math.floor((+new Date() - +new Date(value)) / (h * 60 * 60 * 1000));
      //console.log(seconds);

      if (seconds < 29)
        // less than 30 seconds ago will show as 'Just now'
        return "just now";
      // if (seconds > 3599 && seconds < 7200)
      //     return '1 hour ago'
      // if (seconds > 3600 && seconds < 86400) {
      //     return 'today';
      // }
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + " " + i + " ago"; // singular (1 day ago)
          } else {
            return counter + " " + i + "s ago"; // plural (2 days ago)
          }
      }
    }
    return value;
  }
}

@Pipe({
  name: "gmfDatePipe",
})
export class gmfDatePipe implements PipeTransform {
  transform(input: number, startTime: number, formatType: string): any {
    const me = this;
    let newinput: number;
    if (isNaN(input)) {
      newinput = parseInt(_moment(input).format("X"));
      newinput += startTime * 60;
    } else {
      newinput = _moment.unix(input).startOf("day").unix() + startTime * 60;
    }
    const format = me.dateFormat(formatType);

    if (!input) {
      return null;
    }

    return _moment.unix(newinput).local().format(format);
  }

  dateFormat(formatType: string): string {
    switch (formatType) {
      case "shortDate":
        return "DD MMM YYYY";
      case "normalDate":
        return "DD MMM YY";
      case "normalDateWithTime":
        return "d/M/yy h:mm a";
      case "normalTimeWithDate":
        return "h:mm A, DD/MM/yyyy";
      case "normalTimeWithDateNoComma":
        return "h:mm A DD/MM/yyyy";
      case "fullTimeAndDate":
        return "DD MMM YY HH:mm";
      case "mediumDate":
        return "DD MMM YYYY  hh:mm A";
      case "longDate":
        return "DD MMM YYYY hh:mm:ss A";
      case "normalDateWithDay":
        return "dddd, d/M/yy";
      case "yearMonthDate":
        return "YY-MM-DD";
      case "longTime":
        return "h:mm:ss a";
      case "mediumTime":
        return "h:mm A";
      case "FullYearDateAndTime":
        return "YYYY-MM-DD HH:mm:ss";
      case "FullYearDate":
        return "YYYY-MM-DD";
      case "shortDayAndMonth":
        return "ddd, MMM DD";
      case "GMFShortDate":
        return "MMM DD, YYYY";
    }
  }
}
