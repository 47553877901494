import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";
import { Dialog } from '@syncfusion/ej2-angular-popups';
import { MyNotificationsService } from '../service/notifications.service';
import { MyNotificationLoadedState,MyNotificationLoadingErrorState ,MyNotificationLoadingState } from '../service/notifications.state';
import { Store } from 'src/app/core/store/store';
@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationPopupComponent implements OnInit {

  showNotificationModal = false;
  // The Dialog shows within the target element.
  // public targetElement: HTMLElement;
  loading: boolean = false;
  data: any;
  empty: boolean =false;
  myNotifications:any;
  filteredArr: any[] = [];
  sortArray: any = [{ field: "created_at", order: "asc" }];
  notificationsCount: any;
  fetchedToastMessage: any;
  markAsReadToastMessage: any;
  myNotificationIdArray:any = [];
  forRedDot:boolean =false;
  notificationUnreadCount: any;
  count: number;
  
  constructor(  private MyNotificationsService: MyNotificationsService,
    private router: Router,
    ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const me = this;
   /// this.loadNotificationsInDialog(this.doDefaultRequest());

    document.onclick = (args: any): void => {
      if (args.target.tagName == 'DIV' || args.target.tagName == 'rect') {
        if (me.showNotificationModal == true) {
          me.showNotificationModal = false;
          this.myNotifications = [];
        }
      }
    }
    

  }
  private doDefaultRequest(): any {
    const me = this;
    return {
      p: 0,
      pp: 5,
      sort: this.sortArray, 
      filter:{
        reciever: ""
      }   
    };
  }


  openDialog() {
    if (this.showNotificationModal == false) {
      this.showNotificationModal = true;
       this.loadNotificationsInDialog(this.doDefaultRequest());
    } else {
      this.closeDialog();
    }
  }

  closeDialog() {
    this.showNotificationModal = false;
    this.myNotifications = [];
  }
  
  openNotifications(){
    const me = this;
    me.router.navigate(['/notifications'])
    me.closeDialog();
  }


   /// get notifications list 

   loadNotificationsInDialog(Payload) {
    // console.log("Payload ", Payload);
       const me = this;
       me.MyNotificationsService.getMyNotificationList(Payload).subscribe(
         (state: Store.State) => {
           if (state instanceof MyNotificationLoadingState) {
             me.myNotificationLoading(state);
             return;
           }
           if (state instanceof MyNotificationLoadedState) {
             me.myNotificationLoaded(state);
            //  console.log(state);
             return;
           }
         },
         (state: MyNotificationLoadingErrorState) => {
           me.myNotificationLoadingError(state);
          //  console.log(state);
         }
       );
     }
     myNotificationLoading(state: MyNotificationLoadingState) {
       const me = this; 
       me.loading = true; 
       me.empty = false;
     }
   
     myNotificationLoadingError(state: MyNotificationLoadingErrorState) {
       const me = this;
       me.loading = false; 
       me.empty = false;
     }
   
     myNotificationLoaded(state: MyNotificationLoadedState) {
       const me = this;
       me.loading = false; 
       me.myNotifications = state.data.data.content;
       me.count = state?.data?.data?.totalElements;
       me.myNotifications.map((element)=> me.myNotificationIdArray.push(element.id));
       me.notificationsCount = state.data.data.numberOfElements;
       me.fetchedToastMessage = state.data.message;
       me.empty = me.myNotifications.length > 0 ? false : true;
     }


}
