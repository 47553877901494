import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NgxMaskModule } from "ngx-mask";
import { NgxPermissionsGuard, NgxPermissionsModule } from "ngx-permissions";
import { SessionGuard } from "../core/session/session.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/dashboard",
    pathMatch: "full",
  },

  // Boot
  {
    path: "",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "loading",
    loadChildren: () =>
      import("./loading/loading.module").then((m) => m.LoadingModule),
  },
  {
    path: "error",
    loadChildren: () =>
      import("./error/error.module").then((m) => m.ErrorModule),
  },
  {
    path: "maintenance",
    loadChildren: () =>
      import("./maintenance/maintenance.module").then((m) => m.MaintenanceModule),
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./privacy-policy/privacy-policy.module").then((m) => m.PrivacyPolicyModule),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "access-denied",
    loadChildren: () =>
      import("./access-denied/access-denied.module").then(
        (m) => m.AccessDeniedModule
      ),
  },
  {
    path: "signup",
    loadChildren: () =>
      import("./signup/signup.module").then((m) => m.SignUpModule),
  },

  {
    path: "create-password",
    loadChildren: () =>
      import("./forget-password/create-password/create-password.module").then(
        (m) => m.CreatePasswordModule
      ),
  },
  {
    path: "profile-setup",
    loadChildren: () =>
      import("./profile-setup/profile-setup.module").then(
        (m) => m.ProfileSetupModule
      ),
  },

  // DO NOT ADD ANYTHING ABOVE UNLESS U KNOW WHAT U R DOING
  // DO ADD ANYTHING BELOW THIS LINE

  {
    path: "forget-password",
    loadChildren: () =>
      import("./forget-password/forget-password.module").then(
        (m) => m.ForgetPasswordModule
      ),
  },
  {
    path: "verify-code",
    loadChildren: () =>
      import("./forget-password/verify-code/verify-code.module").then(
        (m) => m.VerifyCodeModule
      ),
  },
  {
    path: '**',
    redirectTo: '/error?e=ROUTE_NOT_FOUND',
  },
];

@NgModule({
  declarations: [
    
  ],
  imports: [
    RouterModule.forRoot(routes, { useHash: false, enableTracing: false }), // UseHash should be false, for msal to work
    NgxPermissionsModule.forChild(),
    NgxMaskModule.forRoot(),
  ],
  providers: [NgxPermissionsGuard, SessionGuard],
  exports: [RouterModule],
})
export class PagesModule { }
