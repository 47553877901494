import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FileUploaderComponent } from "./file-uploader.component";
import { UploaderModule } from "@syncfusion/ej2-angular-inputs";
import { SpinnerModule } from "../spinner/spinner.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ButtonModule } from "@syncfusion/ej2-angular-buttons";
import { DropDownListModule } from "@syncfusion/ej2-angular-dropdowns";
import { DataHandlerModule } from "../data-handler/data-handler.module";
import { DocumentPreviewModule } from "../modal/document-preview/document-preview.module";

@NgModule({
  declarations: [FileUploaderComponent],
  imports: [
    CommonModule,
    ButtonModule,
    FlexLayoutModule,
    UploaderModule,
    SpinnerModule,
    DropDownListModule,
    DataHandlerModule,
    DocumentPreviewModule,
  ],
  exports: [FileUploaderComponent],
})
export class FileUploaderModule {}
