<ejs-tooltip #tooltip [content]="content" (beforeOpen)="beforeOpen($event, data)"
    class="font-size-{{fontSize ? fontSize : '14'}}">
    <span [class.ellipse]="size === undefined" class="{{fontClass}}" #data>
        <ng-container *ngIf="size === undefined;else pipe">
            {{content ? content : defaultContent}}
        </ng-container>
        <ng-template #pipe>
            {{content ? (content | ellipsis: size) : defaultContent}}
        </ng-template>
    </span>
</ejs-tooltip>