import { statusHandler } from "src/app/utility/status-handler.util";
import { Router } from "@angular/router";
import {
  LogoutLoadingState,
  LogoutLoadedState,
  LogoutErrorState,
} from "./../../core/session/session.state";
import { Store } from "src/app/core/store/store";
import { SessionService } from "src/app/core/session/session.service";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import * as moment from "moment";
import { SidebarComponent } from "@syncfusion/ej2-angular-navigations";
import * as _ from "lodash";

import { AppLocalStorage } from "src/app/utility/local-storage.util";
import { SnakbarService } from "../common/snakbar/snakbar.service";
import { SettingMenuDialogComponent } from "../modal/setting-menu-dialog/setting-menu-dialog.component";
import { NotificationPopupComponent } from "src/app/pages/notifications/notification-popup/notification-popup.component";
//import { MyNotificationPayload } from "src/app/pages/notifications/service/my-notification.model";
import { MyNotificationsService } from "src/app/pages/notifications/service/notifications.service";
@Component({
  selector: "app-topnav",
  templateUrl: "./topnav.component.html",
  styleUrls: ["./topnav.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TopnavComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoggedIn$: Observable<boolean>;

  isOpen: boolean = false; // open collection overlay.

  data: any;
  error: boolean;
  empty: boolean;
  loading: boolean;
  @ViewChild('toast') toast: ElementRef;

  @ViewChild("sideNav")
  public sideNav: SidebarComponent;

  @ViewChild(SettingMenuDialogComponent) popUpModal: SettingMenuDialogComponent;
  @ViewChild(NotificationPopupComponent) notificationPopupModal: NotificationPopupComponent;

  notificationData: any;
  notificationEmpty: boolean;
  notificationError: boolean;
  notificationLoading: boolean;
  notificationCount: any;
  logoPath: Observable<string>;
  FirmName: string;
  proRole: any;
  tabLinksLabel: any;
  firstUserLoggedIn: boolean = false;
  isUserGuideCompleted: boolean;
  notification: any = [];
  dates: any;
  isShow: boolean = true;
  filterText: any;
  accountDetails: any;
  queryString: string;
  receiverId: string;
  fromDate: string;
  toDate: string;
  userGuideText: any;
  pagination: {
    p: number;
    pp: number;
  };
  field: string;
  order: string;

  // selected='value';
  value;

  defaultnotificationData: any;

  newNotification: Observable<any>;
  NotificationId: string;
  PresentNotification: boolean;
  isDisabledOn = true;
  selectedDay: any = "30";
  fullName: Observable<string>;
  sortArray: any = [{ field: "created_at", order: "desc" }];

  statusClass = "";

  notificationSubscriber: Subscription;   //for socket
  NotificationResponse: any;
  errorMessage: any;
  NotificationArray: any;
  isNewNotification: boolean = false;

  constructor(
    private MyNotificationsService: MyNotificationsService,
    private snakbarService: SnakbarService,
    public session: SessionService,
    private router?: Router,
    private cd?: ChangeDetectorRef,
    private navigator?: Router,

  ) { }

  ngOnDestroy(): void {
    const me = this;
    // me.notificationSubscriber.unsubscribe();
  }

  tabLinks: object[]; //TODO: add datatype for this

  settingTabLinks: object[];

  ngOnInit(): void {
    const me = this;
    this.getNotificationCount(this.doDefaultRequest());
    //const url = '/notify/unread-count/'+me.session.account.id;
    // me.notificationCount = localStorage?.getItem("notificationCount");
    // me.notificationSubscriber = me.messaging
    //   .watch(url)
    //   .subscribe((messages: MessagingMessage) => {
    //     me.notificationCount=messages.count;
    //   });

    me.firstUserLoggedIn = me.session?.account?.hasUserLoggedInFirstTime;
    //me.isUserGuideCompleted = me.session?.account?.isUserGuideCompleted;

    me.tabLinks = [
      {
        label: "notifications",
        link: "/notifications",
      },
    ];

    // get notification
    var toDate = new Date();
    var fromdate = new Date().setDate(toDate.getDate() - 7);

    me.fromDate = moment(fromdate).startOf("day").format("YYYY-MM-DD HH:mm:SS");
    me.toDate = moment(toDate).endOf("day").format("YYYY-MM-DD HH:mm:SS");

    me.accountDetails = me.session?.account;
    me.receiverId = me.accountDetails?.id;
  }

  ngAfterViewInit() {
    const me = this;
  }

  private doDefaultRequest(): any {
    const me = this;
    return {
      p: 0,
      pp: 20,
      sort: this.sortArray, 
      filter:{
        reciever: ""
      }   
    };
  }
  
  getClass(path: string) {
    // return window.location.pathname.substr(0, path.length) === path && !this.sideNav?.isOpen;
    return window.location.pathname.includes(path);
  }

  openSettingMenuDialog() {
    this.popUpModal.openDialog();
  }

  openAllNotifications(){
 // this.notificationPopupModal.openDialog();
    const me = this;
    let redirectTo = "/notifications";
    me.router.navigate([redirectTo]);
  }

  outSideClick(event: Event) {
    // to close the tooltip when clicked outside
    if (this.popUpModal && event) {
      this.popUpModal.closeDialog();
    }
  }

  // API call to get notification 
  getNotificationCount(Payload){
    const me = this;
    me.MyNotificationsService.getMyNotificationCount(Payload).subscribe(
      (res) => {
        me.NotificationResponse = res;
        // console.log("NotificationResponse ", me.NotificationResponse);
        this.notificationCount=me.NotificationResponse?.data?.totalUnreadNotificationCount;
//  console.log("notificationCount ", this.notificationCount);
        me.NotificationArray = me.NotificationResponse?.data?.content;
      // console.log("NotificationArray ", me.NotificationArray);

         me.isNewNotification = _.some(me.NotificationArray, {readStatus :false})
       // console.log("isNewNotification ", me.isNewNotification);
 
      },
      (error) => {
        me.errorMessage = error;
      }
    );
  }
   
}

