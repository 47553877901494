

    <ejs-dialog id="notificationDialog" #notificationDialog  width="30%" height="55%" 
    [isModal]="true" [visible]="showNotificationModal" [position]="{ X: 'right', Y: 'top' }" >
    <ng-template #content>
        <div class="text-center">
                <div fxLayout="row" fxLayoutAlign="space-between">
                    <span class=" font-size-18 poppins-semibold">Notifications ({{count}}) </span>
                    <span  class="dm-sans-bold font-size-15 clearAll cursor">Clear All</span>
                </div>
                <div class="dateTime mt-10" *ngFor="let singleNotification of myNotifications" >     
                    <div fxLayout="row">
                       <div fxFlex="15" class="icons"[ngSwitch]="singleNotification?.code" >
                        <ng-container *ngSwitchCase="'EV_SESSION_REMINDER'">
                            <img class="mt-7" src="assets/custom/bell.svg" alt="bell" width="18">
                           </ng-container>
                
                           <ng-container *ngSwitchCase="'EV_PROFILE_VERIFICATION'">
                            <img class="mt-7" src="assets/custom/notify.svg" alt="icon" width="18">        </ng-container>
                
                           <ng-container *ngSwitchDefault>This is Default
                           </ng-container>
                         </div>
              
                       <div class="font-size-16 dm-sans-medium notification-text"  fxFlex="70">
                        <p style="color: black;" class="textline">{{singleNotification?.body}}</p>
                       </div>
                       <div fxFlex="15" style="display: none;">
                        <img src="assets/custom/new-cross.svg" class="pt-5 pl-25"  width="14">
                       </div>

                    </div>  
                </div>
            
        </div>
        <div *ngIf="empty" style="position: relative;
        top: 35%;" class="empty-data-container w-100-p" fxLayout="column" fxLayoutAlign="center center">
            <app-empty-data></app-empty-data>
          </div>
        <div *ngIf="loading"  class="spinner">
            <app-spinner id="notification-popup" width="40"></app-spinner>
          </div>
    </ng-template>
    <ng-template #footerTemplate>
        <div fxLayout="row" class="mb-9" fxLayoutAlign="center center">
                <span  class="dm-sans-bold font-size-15 viewAll cursor" (click)="openNotifications()">View All</span>
        </div>
    </ng-template>
</ejs-dialog>
