import { Store } from "src/app/core/store/store";

export const apis = {
  websocket: {
    stomp: {
      endpoint: "http://10.10.10.218:8080/api/ws/notify/unread-count/",
    },
  } as Store.APIConfigGroup,

  auth: {
    me: {
      endpoint: "/service-provider/me",
    },
    validate: {
      endpoint: "/auth/validate",
    },
    forgotPassword: {
      endpoint: "/auth/forgot-password",
    },
    verifyOtp: {
      endpoint: "/auth/otp/token",
    },
    verifyEmail: {
      endpoint: "/auth/verify-email",
    },
  } as Store.APIConfigGroup,

  resetPassword: {
    request: {
      endpoint: "/auth/forgot-password-mailer/jwt",
    },
    verify: {
      endpoint: "/auth/reset-password-mailer",
    },
    reset: {
      endpoint: "/auth/reset-password?token={jwtToken}",
    },
    signUp: {
      endpoint: "/user/register",
    },
    validateToken: {
      endpoint: "/auth/validate-token/jwt/{jwtToken}",
    },
    changePassword: {
      endpoint: "/user/change-password",
    },
    sendVerification: {
      endpoint: "/auth/send-profile-verification-email?email={email}",
    },
  } as Store.APIConfigGroup,

  profile: {
    validateToken: {
      endpoint: "/auth/verify-identity/jwt/{jwtToken}",
    },
    profileSetupKeys: {
      endpoint: "/user/update-profile-setup-keys",
    },
    request: {
      endpoint: "/auth/reset-password-mailer",
    },
    profilePicture: {
      endpoint: "/service-provider/update-profile-picture",
    },
    certificateUpload: {
      endpoint: "/service-provider/certificate/upload",
    },
    setupProfile: {
      endpoint: "/service-provider/update",
    },
    skipProfileSetup: {
      endpoint: "",
    },
    getProfileData: {
      endpoint: "/service-provider/",
    },
    updateProfileData: {
      endpoint: "/service-provider/update",
    },
    certificateVisibility: {
      endpoint: "/service-provider/certificate/change-certificate-visibility",
    },
    deleteCertificate: {
      endpoint: "/service-provider/certificate/delete",
    },
    requestVerification: {
      endpoint: "/service-provider/verification-request",
    },
  } as Store.APIConfigGroup,

  dashboard: {
    getMyProgramsData: {
      endpoint: "/service-provider/get-my-programs",
    },
    getMyProgramsSidePanelData: {
      endpoint: "/program/get-program/{id}",
    },
    getMyUpcomingSessionsData: {
      endpoint: "/service-provider/get-session-schedule",
    },
    getSessionTypeFilterList: {
      endpoint: "/master-data/SESSION_TYPE",
    },
    getUpcomingSessionSidePanel: {
      endpoint: "/program/session/get-session/{id}",
    },
    deleteOneSession: {
      endpoint: "/program/session/cancel-session",
    },
  } as Store.APIConfigGroup,

  admin: {
    getUserDetails: {
      endpoint: "/getAllUsers_Collections",
    },
  } as Store.APIConfigGroup,

  faq: {
    getfaq: {
      endpoint: "/faq/get-faqs/serviceprovider",
    },
  } as Store.APIConfigGroup,
  wallet: {
    getwallet: {
      endpoint: "/wallet-transaction-log/get-all-logs",
    },
    getTotalAmount:{
      endpoint: "/service-provider/get-service-provider-details/{id}",
    },
    getTransactionDetails:{
      endpoint:"/payout-transaction-history/get-transaction-details",
    },
    getExportData:{
      endpoint:"/payout/get-payout-history-excel"
    }
  } as Store.APIConfigGroup,

  

  notifications: {
    getMyNotificationList: {
      endpoint: "/in-app-notification/get-notification-list",
    },
    markAllRead: {
      endpoint: "/in-app-notification/mark-as-read",
    },
  } as Store.APIConfigGroup,

  program: {
    session: {
      endpoint: "/program/session/{id}/start-meeting",
    },
    uploadMedia: {
      endpoint: "/media/upload-files",
    },
    createProgram: {
      endpoint: "/program/create",
    },
    programCredits: {
      endpoint: "/pricing-lookup/get-total-credits-for-program",
    },
    getVerifiedCategories: {
      endpoint: "/sp-verified-categories/get-verified-categories",
    },
    editProgram: {
      endpoint: "/program/edit-program",
    },
    getConflictStatus: {
      endpoint: "/conflict-management/has-conflict",
    },
    getConflictListOnNextButton: {
      endpoint: "/conflict-management/timeslot-conflict",
    },
    getDisclaimer: {
      endpoint: "/getGMFDisclaimer",
    },
  } as Store.APIConfigGroup,

  session: {
    login: {
      endpoint: "/auth/login",
    },
    logout: {
      endpoint: "/auth/logout",
    },

    verifyEmailForSignUp: {
      endpoint: "/user/verify-email",
    },

    token: {
      endpoint: "/auth/token",
    },
    tokenRefresh: {
      endpoint: "/token/refresh",
    },
    googleSignIn: {
      endpoint: "/auth/web/google",
    },
    facebookSignIn: {
      endpoint: "/auth/web/facebook",
    },
  },

  master: {
    getServiceCategories: {
      endpoint: "/master-data/SERVICE_CATEGORY",
    },
  } as Store.APIConfigGroup,
};
