import { Store } from '../store/store';
import { Session, LogoutResponse } from './session.model';


export class SessionCreatingState extends Store.AbstractLoadingState<Session> { }
export class SessionCreatingErrorState extends Store.AbstractErrorState<Session> { }
export class SessionCreatedState extends Store.AbstractIdealState<Session> { }

export class SessionDestroyingState extends Store.AbstractLoadingState<Session> { }
export class SessionDestroyingErrorState extends Store.AbstractErrorState<Session> { }
export class SessionDestroyedState extends Store.AbstractIdealState<Session> { }

export class LogoutLoadingState extends Store.AbstractLoadingState<any> { }
export class LogoutErrorState extends Store.AbstractErrorState<any> { }
export class LogoutLoadedState extends Store.AbstractIdealState<any> { }

export class GoogleSignInLoadingState extends Store.AbstractLoadingState<any> { }
export class GoogleSignInErrorState extends Store.AbstractErrorState<any> { }
export class GoogleSignInLoadedState extends Store.AbstractIdealState<any> { }

export class FacebookSignInLoadingState extends Store.AbstractLoadingState<any> { }
export class FacebookSignInErrorState extends Store.AbstractErrorState<any> { }
export class FacebookSignInLoadedState extends Store.AbstractIdealState<any> { }



